import React from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import RepoCard from 'components/RepoCard'
import SEO from 'components/Seo'
import { IntlProvider } from 'contexts/intl/IntlContext'

export const query = graphql`
  query TopicPostByID($topic: [String]!) {
    file(relativePath: { eq: "joshua-reddekopp-syymxsdnj54-unsplash.jpg" }) {
      absolutePath
      accessTime
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
          src
          sizes
        }
        resize {
          height
          width
        }
      }
      ext
      name
      publicURL
      size
      prettySize
    }
    site {
      siteMetadata {
        title
        ogTitle
        twitterTitle
        description
        ogDescription
        twitterDescription
        image
        url
        siteUrl
        author
      }
    }
    allMarkdownRemark(
      sort: { frontmatter: { publishedAt: DESC } }
      filter: { frontmatter: { topics: { in: $topic } } }
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            publishedAt(formatString: "MMMM DD, YYYY")
            title
            description
            topics
            category
            keywords
            featuredPost
          }
        }
      }
      totalCount
    }
  }
`

const TopicPost = ({ data, location }) => {
  var topicId = location.pathname.replace('/topics/', '').replace('/', '')
  var customTopic = topicId

  const arrayTopic = customTopic.split('-')

  for (var i = 0; i < arrayTopic.length; i++) {
    arrayTopic[i] = arrayTopic[i].charAt(0).toUpperCase() + arrayTopic[i].slice(1)
  }

  customTopic = arrayTopic.join(' ')

  const postsLocale = data.allMarkdownRemark.edges.map(({ node }) => node)

  return (
    <IntlProvider pathname={location.pathname}>
      {({ locale, formatDate, formatMessage, getPath }) => (
        <Layout>
          <SEO
            lang={locale}
            title={customTopic + ' | ' + formatMessage({ id: 'topics.title' })}
            description={formatMessage({ id: topicId + '.description' })}
            keywords={formatMessage({ id: topicId + '.keywords' })}
            isBlogPost={false}
            ogTitle={customTopic + ' | ' + formatMessage({ id: 'topics.title' })}
            ogDescription={formatMessage({ id: topicId + '.description' })}
            ogKeywords={formatMessage({ id: topicId + '.keywords' })}
            ogLocale={locale}
            twitterTitle={customTopic + ' | ' + formatMessage({ id: 'topics.title' })}
            twitterDescription={formatMessage({ id: topicId + '.description' })}
            url={formatMessage({ id: 'meta.url.topics' })}
          />
          <section className='section is-size-4-desktop is-size-5-touch'>
            <h1 className='title has-text-centered has-text-light'>{customTopic}</h1>
            <div className='container'>
              <hr />
              <div className='columns'>
                <div className='column is-half is-offset-one-quarter'>
                  <>
                    {postsLocale.map(post => {
                      let topics = post.frontmatter.topics.map(node => node) //.slice(0, 3)
                      return (
                        <RepoCard
                          key={post.fields.slug}
                          title={post.frontmatter.title}
                          subtitle={post.frontmatter.description.substring(0, 100)}
                          link={`${post.fields.slug}`}
                          image={
                            <img
                              style={{ borderRadius: '0.8em', border: '1em' }}
                              src={data.file.childImageSharp.fluid.src}
                              //     alt={post.fields.slug}
                              layout='constrained'
                              aspectRatio={data.file.childImageSharp.fluid.aspectRatio}
                              transformOptions={{
                                fit: 'cover',
                                cropFocus: 'attention',
                              }}
                              width='200px'
                              backgroundColor='white'
                            />
                          }
                          topics={topics}
                        />
                      )
                    })}
                  </>
                </div>
              </div>
              {/* <p className="has-text-centered">
                <FormattedMessage id="checkOut" />{" "}
                <Link to={getPath("recommended", locale)}>
                  <FormattedMessage id="recommended.title" />
                </Link>
                {"."}
              </p> */}
            </div>
          </section>

          <div className='mt-6'>
            <p className='has-text-centered'>
              {formatMessage({ id: 'checkOut.topics' })}{' '}
              <Link to={getPath('topics')}>{formatMessage({ id: 'stack.topics' })}</Link>
            </p>
          </div>
        </Layout>
      )}
    </IntlProvider>
  )
}

TopicPost.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default TopicPost
